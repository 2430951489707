import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const About = () => (
  <Layout>
    <h1>About Gussy the Newfidor</h1>
    <h2>Origin Story</h2>
    <p>My Mom was a Newfoundland. My Dad was a rogue labrador</p>
    <p>I have 2 brothers.  One, Bear he is a bully.</p>
    <p>and I Love Newfidors</p>
    <Link to="/">&larr; back to home</Link>
  </Layout>
);

export default About;
